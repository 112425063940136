//
// Menu for small screens
//
(function () {
  var menuButton = document.querySelector('#menu-button');
  var menuList = document.querySelector('#picture-only-link');
  var languagesButton = document.querySelector('#languages-button');
  var languagesList = document.querySelector('#languages-menu');

  if (menuButton) {
    menuButton.addEventListener('click', function () {
      if (languagesButton) {
        if (languagesList.style.display === 'block') {
          languagesList.style.display = 'none';

          languagesButton.classList.toggle('active');
        }
      }

      if (menuList.style.display !== 'block') {
        menuList.style.display = 'block';
      } else {
        menuList.style.display = 'none';
      }

      menuButton.classList.toggle('active');
    });
  }

  if (languagesButton) {
    languagesButton.addEventListener('click', function () {
      if (menuList.style.display === 'block') {
        menuList.style.display = 'none';
        menuButton.classList.toggle('active');
      }
      if (languagesList.style.display !== 'block') {
        languagesList.style.display = 'block';
      } else {
        languagesList.style.display = 'none';
      }
      languagesButton.classList.toggle('active');
    });
  }
})();

// Add placeholder event function for Simple Analytics sa_event
(function () {
  window.sa_event =
    window.sa_event ||
    function () {
      var a = [].slice.call(arguments);
      window.sa_event.q ? window.sa_event.q.push(a) : (window.sa_event.q = [a]);
    };
})();

//
// Share buttons
// FB, LinkedIn Reddit, Bluesky and copying a link
//
(function () {
  if (!document.querySelector('#share-buttons')) return;

  var linkButton = document.querySelector('#link-button');
  var redditButton = document.querySelector('#reddit-button');
  var blueskyButton = document.querySelector('#bluesky-button');

  linkButton.addEventListener('click', function () {
    var tempElement = document.createElement('input'),
      text = window.location.href;

    document.body.appendChild(tempElement);
    tempElement.value = text;
    tempElement.select();
    document.execCommand('copy');
    document.body.removeChild(tempElement);

    var messageElement = document.getElementById('social-copied-message');
    messageElement.innerText = 'Link copied!';

    messageElement.style.display = 'inline-block';
    setTimeout(function () {
      messageElement.style.display = 'none';
    }, 4000);

    sa_event && sa_event('link_copied');
  });

  redditButton.addEventListener('click', function () {
    const link = `https://www.reddit.com/submit?url=${window.location.href}`;
    window.open(link, '_blank').focus();

    sa_event && sa_event('share', { shareLocation: 'Reddit' });
  });

  blueskyButton.addEventListener('click', function () {
    const link = `https://bsky.app/intent/compose?text=${window.location.href}`;
    window.open(link, '_blank').focus();

    sa_event && sa_event('share', { shareLocation: 'Bluesky' });
  });
})();

//
// Language Variety switcher
// Navigate using JS so that bots don't follow the link
//
(function () {
  if (!document.querySelector('#english-lv-selector')) return;

  var usButton = document.querySelector('#lv-switcher-us-english');
  var ukButton = document.querySelector('#lv-switcher-uk-english');

  usButton.addEventListener('click', function () {
    const currentUrl = window.location.href;

    const updatedUrl = new URL(currentUrl);
    updatedUrl.hash = '';
    updatedUrl.searchParams.set('lv', 'us-english');

    window.location.href = updatedUrl.href;
  });
  ukButton.addEventListener('click', function () {
    const currentUrl = window.location.href;

    const updatedUrl = new URL(currentUrl);
    updatedUrl.hash = '';
    updatedUrl.searchParams.set('lv', 'uk-english');

    window.location.href = updatedUrl.href;
  });

  // Check if the element with id 'lv-switcher-uk-english' has the 'selected' class
  // If so update the URL to reflect the page state
  if (ukButton && ukButton.classList.contains('selected')) {
    // Add or modify URL parameter here
    const currentUrl = new URL(window.location.href);
    currentUrl.searchParams.set('lv', 'uk-english');

    // Update the browser URL (without reloading the page)
    window.history.pushState({}, '', currentUrl);
  }
})();

// From Pure CSS
(function (window, document) {
  'use strict';

  // Enable drop-down menus in Pure
  // Inspired by YUI3 gallery-simple-menu by Julien LeComte
  // [https://github.com/yui/yui3-gallery/blob/master/src/gallery-simple-menu/js/simple-menu.js]

  function PureDropdown(dropdownParent) {
    var PREFIX = 'pure-',
      ACTIVE_CLASS_NAME = PREFIX + 'menu-active',
      ARIA_ROLE = 'role',
      MENU_OPEN = 0,
      MENU_CLOSED = 1,
      MENU_ACTIVE_SELECTOR = '.pure-menu-active',
      MENU_LINK_SELECTOR = '.pure-menu-link',
      MENU_SELECTOR = '.pure-menu-children',
      DISMISS_EVENT =
        window.hasOwnProperty && window.hasOwnProperty('ontouchstart')
          ? 'touchstart'
          : 'mousedown',
      ARROW_KEYS_ENABLED = true,
      ddm = this; // drop down menu

    this._state = MENU_CLOSED;

    this.show = function () {
      if (this._state !== MENU_OPEN) {
        this._dropdownParent.classList.add(ACTIVE_CLASS_NAME);
        // Removed aria hidden as it was causing problems
        this._state = MENU_OPEN;
      }
    };

    this.hide = function () {
      if (this._state !== MENU_CLOSED) {
        this._dropdownParent.classList.remove(ACTIVE_CLASS_NAME);
        this._link.focus();
        this._state = MENU_CLOSED;
      }
    };

    this.toggle = function () {
      this[this._state === MENU_CLOSED ? 'show' : 'hide']();
    };

    this.halt = function (e) {
      e.stopPropagation();
      e.preventDefault();
    };

    this._dropdownParent = dropdownParent;
    this._link = this._dropdownParent.querySelector(MENU_LINK_SELECTOR);
    this._menu = this._dropdownParent.querySelector(MENU_SELECTOR);
    this._firstMenuLink = this._menu.querySelector(MENU_LINK_SELECTOR);

    // Set ARIA attributes
    this._link.setAttribute('aria-haspopup', 'true');
    this._menu.setAttribute(ARIA_ROLE, 'menu');
    this._menu.setAttribute('aria-labelledby', this._link.getAttribute('id'));
    [].forEach.call(this._menu.querySelectorAll('li'), function (el) {
      el.setAttribute(ARIA_ROLE, 'presentation');
    });
    [].forEach.call(this._menu.querySelectorAll('a'), function (el) {
      el.setAttribute(ARIA_ROLE, 'menuitem');
    });

    // Toggle on click
    this._link.addEventListener('click', function (e) {
      e.stopPropagation();
      e.preventDefault();
      ddm.toggle();
    });

    // Keyboard navigation
    document.addEventListener('keydown', function (e) {
      var currentLink, previousSibling, nextSibling, previousLink, nextLink;

      // if the menu isn't active, ignore
      if (ddm._state !== MENU_OPEN) {
        return;
      }

      // if the menu is the parent of an open, active submenu, ignore
      if (ddm._menu.querySelector(MENU_ACTIVE_SELECTOR)) {
        return;
      }

      currentLink = ddm._menu.querySelector(':focus');

      // Dismiss an open menu on ESC
      if (e.keyCode === 27) {
        /* Esc */
        ddm.halt(e);
        ddm.hide();
      }
      // Go to the next link on down arrow
      else if (ARROW_KEYS_ENABLED && e.keyCode === 40) {
        /* Down arrow */
        ddm.halt(e);
        // get the nextSibling (an LI) of the current link's LI
        nextSibling = currentLink ? currentLink.parentNode.nextSibling : null;
        // if the nextSibling is a text node (not an element), go to the next one
        while (nextSibling && nextSibling.nodeType !== 1) {
          nextSibling = nextSibling.nextSibling;
        }
        nextLink = nextSibling
          ? nextSibling.querySelector('.pure-menu-link')
          : null;
        // if there is no currently focused link, focus the first one
        if (!currentLink) {
          ddm._menu.querySelector('.pure-menu-link').focus();
        } else if (nextLink) {
          nextLink.focus();
        }
      }
      // Go to the previous link on up arrow
      else if (ARROW_KEYS_ENABLED && e.keyCode === 38) {
        /* Up arrow */
        ddm.halt(e);
        // get the currently focused link
        previousSibling = currentLink
          ? currentLink.parentNode.previousSibling
          : null;
        while (previousSibling && previousSibling.nodeType !== 1) {
          previousSibling = previousSibling.previousSibling;
        }
        previousLink = previousSibling
          ? previousSibling.querySelector('.pure-menu-link')
          : null;
        // if there is no currently focused link, focus the last link
        if (!currentLink) {
          ddm._menu
            .querySelector('.pure-menu-item:last-child .pure-menu-link')
            .focus();
        }
        // else if there is a previous item, go to the previous item
        else if (previousLink) {
          previousLink.focus();
        }
      }
    });

    // Dismiss an open menu on outside event
    document.addEventListener(DISMISS_EVENT, function (e) {
      var target = e.target;
      if (target !== ddm._link && !ddm._menu.contains(target)) {
        ddm.hide();
        ddm._link.blur();
      }
    });
  }

  function initDropdowns() {
    var dropdownParents = document.querySelectorAll('.pure-menu-has-children');
    for (var i = 0; i < dropdownParents.length; i++) {
      var ddm = new PureDropdown(dropdownParents[i]);
    }
  }

  initDropdowns();
})(this, this.document);
