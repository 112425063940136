<script setup>
const cookieClickedAccept = () => {
  window.dataLayer = window.dataLayer || [];

  function gtag() {
    dataLayer.push(arguments);
  }

  const GTAG_ID = import.meta.env.VITE_GTAG_ID;

  gtag('consent', 'update', {
    ad_storage: 'denied',
    analytics_storage: 'granted'
  });

  gtag('js', new Date());
  gtag('config', GTAG_ID);
};

function checkLocale() {
  let locale = 'en';
  if (
    window.location.pathname.includes('/ja/') ||
    window.location.pathname === '/ja'
  ) {
    locale = 'ja';
  } else if (
    window.location.pathname.includes('/de/') ||
    window.location.pathname === '/de'
  ) {
    locale = 'de';
  } else if (
    window.location.pathname.includes('/es/') ||
    window.location.pathname === '/es'
  ) {
    locale = 'es';
  }

  return locale;
}

const locale = checkLocale();
</script>

<template>
  <vue-cookie-accept-decline
    :debug="false"
    :disableDecline="false"
    :showPostponeButton="false"
    @clicked-accept="cookieClickedAccept"
    elementId="ga-confirmation"
    position="bottom-left"
    ref="ga-confirmation"
    transitionName="slideFromBottom"
    type="floating"
  >
    <!-- Optional -->
    <template v-if="locale === 'ja'" #message>
      ウェブサイトを動作させるために、重要なクッキーを使用しています。
      ウェブサイトの改善のために、分析クッキーを使用したいです。
    </template>
    <template v-else-if="locale === 'de'" #message>
      Wir verwenden einige essentielle Cookies, um diesen Service funktionsfähig
      zu machen. Wir möchten auch Analyse-Cookies verwenden, um Verbesserungen
      an der Website vorzunehmen.
    </template>
    <template v-else-if="locale === 'es'" #message>
      Utilizamos algunas cookies esenciales para que este servicio funcione.
      También nos gustaría usar cookies de análisis para mejorar el sitio web.
    </template>
    <template v-else #message>
      We use some essential cookies to make this service work. We would also
      like to use analytics cookies to make improvements to the website.
    </template>

    <!-- Optional -->
    <template #declineContent>
      <template v-if="locale === 'ja'">辞退する</template>
      <template v-else-if="locale === 'de'">Zulassen</template>
      <template v-else-if="locale === 'es'">Rechazar</template>
      <template v-else>No thanks</template>
    </template>

    <!-- Optional -->
    <template #acceptContent>
      <template v-if="locale === 'ja'">受け入れる</template>
      <template v-else-if="locale === 'de'">Ablehnen</template>
      <template v-else-if="locale === 'es'">Aceptar</template>
      <template v-else>Allow</template>
    </template>
  </vue-cookie-accept-decline>
</template>
